import React from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY
const stripeOptions = { fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Jost&display=swap" }] };

const StripeWrapper = ({ children, options = null }) => {
    const stripeLib = loadStripe(PUBLIC_KEY);
    return (
        <Elements options={{ ...stripeOptions, ...options }} stripe={stripeLib}>{children}</Elements>
    )
}
export default StripeWrapper