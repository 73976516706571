import React, { useState } from 'react'
import BasicModal from './Modal'
import ReactPlaceholder from 'react-placeholder'

const RequestModal = ({ infoModal, setInfoModal, formik = undefined }) => {
    return (
        <BasicModal
            modal={infoModal}
            setModal={setInfoModal}
            mode={'lg'}
            heading={"Important Pricing Information"}
        >
            <div className="p-1 additional-fees-wrapper">
                {/* {
                    formik?.values?.is_healthie != "2" && (
                        <>
                            <h3 className="heading-fees1">Additional fees</h3>
                            <p className="heading-para">Visits fees are not included and vary by insurance plan and consult modality, but normally are $30 for a video consult (with insurance) or $49 (without insurance) and $18 for an asynchronous refill consult with or without insurance. Laboratory fees are not included and vary by insurance plan, but you always have the option to reach out to us and request a $35 cash-pay lab order. </p>
                        </>
                    )
                } */}
                <div className='additional-list-wrapper'>
                    <h3 className="heading-fees">Monthly medication fees are not included and vary by pharmacy and health plan:</h3>
                    <ul className='d-flex flex-column gap-1 p-0'>
                        <li>
                            <h3 className="heading-fees">Wegovy:</h3>
                            <p className="heading-para">As low as $0 with coupon and insurance (<a href="https://www.wegovy.com/coverage-and-savings/save-on-wegovy.html" className='text-voilet-light'>https://www.wegovy.com/coverage-and-savings/save-on-wegovy.html</a>) or $1,300+ depending on pharmacy (without coupon and out of pocket) </p>
                        </li>
                        <li>
                            <h3 className="heading-fees">Zepbound:</h3>
                            <p className="heading-para">As low as $25 with coupon and insurance (<a href="https://www.zepbound.lilly.com/coverage-savings" className='text-voilet-light'>https://www.zepbound.lilly.com/coverage-savings</a>) or $1,050+ depending on pharmacy (without coupon and out of pocket) </p>
                        </li>
                        <li>
                            <h3 className="heading-fees">Mounjaro:</h3>
                            <p className="heading-para">As low as $25 with coupon and insurance (<a href="https://www.mounjaro.com/savings-resources#savings" className='text-voilet-light'>https://www.mounjaro.com/savings-resources#savings</a>) or $990+ depending on pharmacy (without coupon and out of pocket) </p>
                        </li>
                        <li>
                            <h3 className="heading-fees">Ozempic:</h3>
                            <p className="heading-para">As low as $25 with coupon and insurance (<a href="https://www.ozempic.com/savings-and-resources/save-on-ozempic.html" className='text-voilet-light'>https://www.ozempic.com/savings-and-resources/save-on-ozempic.html</a>) or $900+ depending on pharmacy (without coupon and out of pocket) </p>
                        </li>
                        <li>
                            <h3 className="heading-fees">Other:</h3>
                            <p className="heading-para">~$267 to $590 per month for similarity effective out-of-pocket injectable medications.</p>
                        </li>

                    </ul>
                </div>
            </div>
        </BasicModal>
    )
}

export default RequestModal