import {
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { createUserCharge, updateCardStripeCharge } from "../../lib/helper/stripe";
const StripeModifyCard = ({
    profile,
    paymentAction,
}) => {
    const [cardFilled, setCardFilled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cardError, setCardErrord] = useState("")
    const stripe = useStripe();
    const elements = useElements();

    useEffect(async () => {
        if (elements != null) {
            const cardElement = elements.getElement(CardElement);
            if (cardElement && cardElement != null) {
                cardElement.on("change", function (event) {
                    setCardErrord("")
                    if (event.complete) {
                        setCardFilled(true);
                    } else {
                        setCardFilled(false);
                    }
                });
            }
        }
    }, [elements, profile]);

    const paymentHandler = async () => {
        if (!stripe || !elements) { return; }
        const cardElement = elements.getElement(CardElement);
        setLoading(true);
        try {
            let paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (paymentMethod?.paymentMethod?.id) {
                let payload = {
                    customerId: profile?.customer_id,        
                    // customerId: "cus_PBNMgihKITL45d",
                    paymentMethod: paymentMethod.paymentMethod.id,
                };
                const create = await updateCardStripeCharge(payload);
                if (create && create?.data?.id) {
                    paymentAction()
                } else {
                    setLoading(false);
                    swal("Your payment method not updated. Please try again!")
                }
            }

        } catch (error) { console.log(error) }

        setLoading(false)
    };


    const cardStyle = {
        style: {
            hidePostalCode: true,
            base: {
                color: "#000000",
                fontFamily: "Jost",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                letterSpacing: "0px",
                lineHeight: 1.8,

                "::placeholder": {
                    color: "#8898aa",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    return (
        <div className="row mt-2">
            <div className="col-md-12">
                <div className="container-input-credit-card">
                    <CardElement options={cardStyle} className={`form-control pt-2 ${cardError && "card-error-input"}`} />
                    <span>{cardError}</span>
                </div>
                {loading == true ? (
                    <button className="btn btn-lg-secondary w-100 mt-3" disabled>
                        Loading...
                    </button>
                ) : (
                    <button
                        onClick={paymentHandler}
                        className="btn btn-lg-secondary w-100 mt-3"
                        disabled={cardFilled == false}
                    >
                        <i className="fa fa-lock" aria-hidden="true"></i>{" "}
                        Confirm Payment Method
                    </button>
                )}

            </div>
        </div>
    );
};
export default StripeModifyCard;
