
import axios from "axios";

//Subscription
export const createUserSubscription = async (data) => {
  try {
    const response = await axios.post(`api/stripe/subscription`,  data);
    return response;
  } catch (error) {
    return error
  }
}

export const getStripeSubscriptions = async (customer) => {
  try {
    const response = await axios.get(`api/stripe/subscription?customerId=${customer}`);
    return response;
  } catch (error) {
    return error
  }
}

export const updateSubcription = async (subscriptionId, data) => {
  try {
    const response = await axios.patch(`api/stripe/subscription?subscriptionId=${subscriptionId}`,  data);
    return response;
  } catch (error) {
    return error
  }
}

//Payment
export const createPaymentCapture = async (data) => {
  try {
    const response = await axios.post(`api/stripe/capture`,  data);
    return response;
  } catch (error) {
    return error
  }
}

export const createPaymentIntent = async (data) => {
  try {
    const response = await axios.post(`api/stripe/paymentIntent`,  data);
    return response;
  } catch (error) {
    return error
  }
}

//Payment

export const createUserCharge = async (data) => {
  try {
    const response = await axios.post(`api/stripe/charge`,  data);
    return response;
  } catch (error) {
    return error
  }
}
export const createSingleCharge = async (data) => {
  try {
    const response = await axios.post(`api/stripe/payment`,  data);
    return response;
  } catch (error) {
    return error
  }
}
export const updateCardStripeCharge = async (data) => {
  try {
    const response = await axios.post(`api/stripe/updateMethod`,  data);
    return response;
  } catch (error) {
    return error
  }
}

export const createCustomer = async (data) => {
  try {
    const response = await axios.post(`api/stripe/customer`,  data);
    return response;
  } catch (error) {
    return error
  }
}


export const createCheckoutSession = async (data) => {
  try {
    const response = await axios.post(`api/stripe/checkoutSession`,  data);
    return response;
  } catch (error) {
    return error
  }
}


export const getInvoice = async (invoiceId) => {
  try {
    const response = await axios.get(`api/stripe/invoice?id=${invoiceId}`);
    return response;
  } catch (error) {
    return error
  }
}

export const payStripeInvoice = async (data) => {
  try {
    const response = await axios.post(`api/stripe/invoice`,  data);
    return response.data;
  } catch (error) {
    return error
  }
}

export const createStripeNewCard = async (data) => {
  try {
    const response = await axios.post(`api/stripe/paymentMethod`, data);
    return response.data;
  } catch (error) {
    return error
  }
}

export const createSetupIntent = async (data) => {
  try {
    const response = await axios.post(`api/stripe/setupIntent`, data);
    return response.data;
  } catch (error) {
    return error
  }
}