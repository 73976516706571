
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Navbar,
} from "reactstrap";
import Link from "next/link";
import React from 'react'
const ServicesModal = ({ modal, setModal, modalToggle }) => {
    return (
        <div>
            <Modal isOpen={modal} size={"lg"}>
                <ModalHeader toggle={modalToggle}>
                    POSHDOC TERMS OF USE
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="pdf-links-data scroll-down">

                            <p>These Terms of Use (“TOU”) are an agreement between you and Helio Logistics, Inc., a Delaware
                                business corporation operating under the trade name of PoshDoc (“PoshDoc”) concerning your access to,
                                and use of, various services offered through our website [www.poshdoc.com], and any web-based
                                and/or mobile applications that require you to create an account in order to use the Services (as such term
                                is hereinafter defined) (collectively, the “Site”) By accessing, browsing, and/or using the Site, you accept
                                and agree to be bound by the TOU and the Privacy Policy (collectively, the “Terms and Conditions”). If
                                you do not agree to all of these Terms and Conditions, do not access or use the Site. If you have questions
                                regarding these Terms and Conditions, please contact us at Team@poshdoc.com.
                            </p>
                            <p>These TOU may change. Because your use of the Site means that you agree to these TOU, if we revise
                                and update these TOU, your continued use of the Services and the Site will mean that you accept these
                                changes. You choose whether or not to use the Services and the Site. If you do not agree to all of these
                                TOU, please do not use the Services and the Site.
                            </p>
                            <p><b>IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR DOCTOR OR DIAL
                                911.</b></p>
                            <p><b>IMPORTANT NOTICE: YOUR USE OF OUR SITE IS SUBJECT TO AN ARBITRATION
                                PROVISION IN THIS AGREEMENT, REQUIRING ALL CLAIMS TO BE RESOLVED BY WAY
                                OF BINDING ARBITRATION. PLEASE CAREFULLY REVIEW THE ARBITRATION
                                PROVISION OF THIS AGREEMENT FOR MORE INFORMATION.</b></p>
                            <p><b>Your Relationship with PoshDoc</b></p>
                            <p>PoshDoc, on its own behalf and on behalf of one or more professional corporations incorporated, formed or authorized in one or more states and for which PoshDoc provides administrative services, including but not limited to (collectively, the “Professional Entities”), including but not limited to OpenLoop Healthcare Partners, PC, OpenLoop Healthcare Partners California, PC, OpenLoop Healthcare Partners Colorado, PC, OpenLoop Healthcare Partners New Jersey, PC, and OpenLoop Healthcare Partners Wisconsin, SC (collectively “OpenLoop”), Beluga Health CO PA, Beluga Health Corp, Beluga Health PC, Beluga Health PA, Beluga Health PC, Beluga Health PC, Beluga Health PC of New Jersey, makes certain information available to you regarding remote weight loss treatment programs (“Telehealth Visits”) and facilitates your access to telemedicine and expert medical services provided by the Professional Entities (the “Services”). Our Privacy Policy, which may be found here <a href="https://www.poshdoc.com/privacy_policy" target="blank">https://www.poshdoc.com/privacy_policy</a>, details how we may use, share and maintain any information that you provide to us or to the Professional Entities. PoshDoc’s role is limited to making such information available to you and/or facilitate your access to the Services, on behalf of the Professional Entities as their “business associate” as that term is defined under the Health Insurance Portability and Accountability Act of 1996, as amended, and its implementing regulations (“HIPAA”). PoshDoc is independent from the Professional Entities and the healthcare providers that may provide you with telehealth services through the Professional Entities. PoshDoc is not responsible for the Professional Entities’ acts, omissions or for any content of the communications made by them to you. PoshDoc does not engage in the practice of medicine or provide any other health services.</p>
                            <p>PoshDoc itself does not offer any diagnosis or treatment. ALL INFORMATION PROVIDED ON THIS
                                SITE OR IN CONNECTION WITH ANY COMMUNICATIONS SUPPORTED BY POSHDOC IS
                                INTENDED TO BE FOR GENERAL INFORMATION PURPOSES ONLY AND IS IN NO WAY
                                INTENDED TO CREATE A PROVIDER-PATIENT RELATIONSHIP AS DEFINED BY STATE AND
                                FEDERAL LAW. USE OF THE PoshDoc SITE IS NOT A SUBSTITUTE FOR PROFESSIONAL
                                DIAGNOSIS OR TREATMENT AND RELIANCE ON ANY INFORMATION PROVIDED BY
                                POSHDOC IS SOLELY AT YOUR OWN RISK</p>
                            <p><b>Your Relationship with PoshDoc</b></p>
                            <p>In connection with accessing the Services and Telehealth Visits, you acknowledge, understand, consent
                                and agree to the following:
                            </p>
                            <p>1. Telehealth Visits involve the use of electronic communications to enable the providers
                                under contract and/or employed by the Professional Entities (the “Providers”) at different locations to
                                share individual patient medical information for the purpose of providing the Services and improving
                                patient care.</p>
                            <p>2. A Telehealth Visit is not the same as an in-person direct patient/healthcare provider visit,
                                because you will not be in the same room as the Provider providing the Services.
                            </p>
                            <p>3. You understand that parts of your care and treatment that require physical tests or
                                examinations may be conducted by providers other than the Provider.</p>
                            <p>4. The communications systems used during the Telehealth Visits will incorporate
                                reasonable security protocols to protect the confidentiality of patient information and will include
                                reasonable measures to safeguard the data and to ensure its integrity against intentional or unintentional
                                corruption.
                            </p>
                            <p>5. Nevertheless, you understand that there are potential risks to the use of this technology
                                during the Telehealth Visits, including but not limited to:</p>
                            <ul>
                                <li><small>Delays in medical evaluation and consultation or treatment may occur due to
                                    deficiencies or failures of the equipment or network interruption;
                                </small></li>
                                <li><small>Security protocols could fail, causing a breach of privacy of personal health
                                    information;
                                </small></li>
                                <li><small>Lack of access to complete medical records may result in adverse drug
                                    interactions or allergic reactions or other negative outcomes; and</small></li>
                            </ul>
                            <p>6. If applicable, you are aware that either the Provider or you can discontinue the Telehealth
                                Visit if we believe that the videoconferencing connections are not adequate for the situation.
                            </p>
                            <p>7. You understand that the Telehealth Visit will not be audio or video recorded at any time,
                                and you agree not to audio or video record the Telehealth Visit.
                            </p>
                            <p>8. In connection with a Telehealth Visit, you may be requested by a Provider to upload,
                                post, publish or display (hereinafter, “upload”) images, videos, photographs, information, data, text,
                                messages or other materials (“content”). You will ensure that all content you upload or share with the
                                Professional Entities and the Providers complies with all applicable laws, rules and regulations, is
                                appropriate and non-offensive, and that you have all necessary rights to use, share, and/or upload such
                                content, without infringing any third party rights.</p>
                            <p>9. To the extent applicable, you hereby consent and authorize the Professional Entities and
                                any Provider associated with the Professional Entities to review and use content you have uploaded to the
                                Site in connection with the Services provided to you pursuant to any Telehealth Visit.</p>
                            <p>10. You agree that the Professional Entities may use and disclose your Protected Health
                                Information (as that term is defined under HIPAA) in accordance with applicable law and the Notice of
                                Privacy Practices provided to you.
                            </p>
                            <p>11. You acknowledge that you have the right to request the following:</p>
                            <ul>
                                <li><span>Omission of specific details of your medical history/physical examination that
                                    are personally sensitive, or
                                </span></li>
                                <li><span>Asking non-medical personnel to leave the room where the Provider is
                                    conducting the Telehealth Visit at any time if their presence is not mandated for safety concerns,
                                    or</span></li>
                                <li><span>Termination of the service at any time.</span></li>
                            </ul>
                            <p>12. You agree that you are entering into an agreement with the Professional Entities which
                                shall be a provider of the Services to you, which means, among other things, you are entering into a
                                practitioner-patient relationship with the Provider associated with the Professional Entities that personally
                                performs the Services.</p>
                            <p>13. You understand and agree that PoshDoc is the provider of certain administrative services
                                to the Professional Entities and does not provide professional medical services itself.
                            </p>
                            <p>14. In connection with the Services and the provision of Telehealth Visits</p>
                            <p>15. Neither PoshDoc nor the Professional Entities is a drug fulfillment warehouse. In the
                                event that a Provider associated with the Professional Entities does prescribe a medication, he/she will
                                limit the supply based on state regulations and will only prescribe a medication, as determined appropriate
                                in his/her sole discretion and professional judgment. The Professional Entities does not guarantee that a
                                prescription will be written.</p>
                            <p>16. You agree that any prescriptions that you acquire from a Provider shall be solely for your
                                personal use. You agree to fully and carefully read all product information and labels and to contact a
                                physician or pharmacist if you have any questions regarding the prescription.
                            </p>
                            <p><b>Payment for Services.
                            </b></p>
                            <p>In connection with your enrollment with PoshDoc, you acknowledge and agree that the Professional
                                Entities may submit claims on your behalf for the Services to the extent such Professional Entities are
                                participating providers with, any federal or state healthcare programs (i.e., Medicare, Medicaid) or any
                                third party commercial payor (collectively, “Payor Reimbursement”). The Professional Entities may elect
                                to accept as payment in full any and all Payment Reimbursement from any federal or state healthcare
                                program or any third party commercial payor for the Services.</p>
                            <p>By agreeing to use the Services, you acknowledge and agree that: (1) to the extent the Services are a
                                covered service under any applicable federal or state healthcare programs or any third party commercial
                                payor, you hereby authorize PoshDoc and the Professional Entities to submit claims on your behalf to
                                Payor Reimbursement; (2) to the extent the Services are a covered service under any applicable federal or
                                state healthcare programs or any third party commercial payor, you hereby authorize PoshDoc and the
                                3
                                Professional Entities to release any and all medical and other information to process any claims for Payor
                                Reimbursement and assign or otherwise authorize payment directly to PoshDoc and/or the Professional
                                Entities for Payor Reimbursement with respect to the Services; (3) to the extent Payor Reimbursement
                                does not fully reimburse PoshDoc and the Professional Entities for the cost of the Services, you authorize
                                and agree that PoshDoc may charge your Payment Method (as herein defined) for amounts not otherwise
                                covered under Payor Reimbursement; or (4) to the extent you are choosing not use a federal and/or state
                                healthcare program or third party commercial payor for Services, you are explicitly choosing to obtain
                                products and services on a cash basis outside of such programs and you have sole financial responsibility
                                for all Services provided to you. SUBMISSION OF CLAIMS DOES NOT WAIVE OUR RIGHT
                                TO SEEK PAYMENT DIRECTLY FROM YOU.
                            </p>
                            <p>Subject to any ability to refund as hereinafter set forth, you agree that you will be charged for the
                                Services, by providing a credit card or other payment method accepted by PoshDoc (“Payment Method”),
                                and you are expressly agreeing that PoshDoc, LabPass Inc., and other wholly-owned subsidiaries
                                of Southern Health Systems, Inc. are authorized to charge to the Payment Method any fees for
                                Services, together with any applicable taxes, to the extent, if applicable not covered by Payor
                                Reimbursement.
                            </p>
                            <p>You agree that authorizations to charge your Payment Method remains in effect until you cancel it in
                                writing, and you agree to notify PoshDoc of any changes to your Payment Method. You certify that you
                                are an authorized user of the Payment Method and will not dispute charges for the Services. You
                                acknowledge that the origination of ACH transactions to your account must comply with applicable
                                provisions of U.S. law. In the case of an ACH transaction rejected for insufficient funds, PoshDoc may at
                                its discretion attempt to process the charge again at any time within 30 days.</p>
                            <p><b>Your Representations, Warranties and Covenants to PoshDoc
                            </b></p>
                            <p>By accessing the Site, you represent and warrant the following: (i) you are at least eighteen (18) years of
                                age, (ii) you have the legal ability and authority to enter into these TOU with PoshDoc , (iii) the
                                information you have provided to PoshDoc in your registration is accurate and complete, (iv) you will
                                comply with any and all laws applicable to your use of the Site (v) you will not interfere with a third
                                party’s use and enjoyment of the Site (vi) you will not interfere with or disrupt PoshDoc’s or its vendors’
                                security measures, (vii) if any information you provide to PoshDoc becomes inaccurate, incomplete or
                                otherwise false or misleading, you will immediately notify PoshDoc, and (viii) you are accessing the Site
                                for yourself.
                            </p>
                            <p>In connection with your relationship with PoshDoc, you may provide to us, or cause to be provided to us
                                on your behalf, personal information, including health information. We will use your information in
                                accordance with our Privacy Policy which may be found here [link].
                            </p>
                            <p>You are responsible for providing and maintaining, at your own risk, option, and expense, appropriate
                                software and hardware capabilities (consistent with any technical, quality, or other requirements described
                                on the Site) to enable use of the Site, including, but not limited to, a computer or mobile device with
                                internet access. You are solely responsible for any fees, including internet connection or mobile fees that
                                you incur while using the Site. You also have a duty to provide truthful, accurate, and complete
                                information in any forms or other communications you submit to or through the Site. We reserve the right
                                to change the access configuration, including any software, hardware, or other requirements of the Site at
                                any time without prior notice.
                            </p>
                            <p><span>Access to the Site.</span></p>
                            <p>This Site, our systems, our databases, and any and all other information, data, documents, materials,
                                works and other content, devices, methods, processes, hardware, software and other technologies and
                                inventions, including any technical or functional descriptions, requirements, plans, specifications, or
                                reports, that are provided or used by PoshDoc or any subcontractor engaged by PoshDoc in connection
                                with this Site or otherwise comprised or related to this Site, the designs of each of the foregoing, and any
                                and all intellectual property rights in the foregoing (collectively, the “PoshDoc Materials”) shall at all
                                times remain the exclusive property of PoshDoc and its third-party licensors. Any third-party software
                                included in this Site is licensed subject to the additional terms of the applicable third-party license.</p>
                            <p>Our Site may include or provide access to information, software, photos, videos, text, graphics, music,
                                sounds, and other material or information provided by us or third parties (collectively, the “PoshDoc
                                Content”) that are protected by copyrights, patents, trademarks, trade secrets, or other intellectual property
                                laws. You are not acquiring any rights in or to the PoshDoc Content other than a non-exclusive right to
                                access and use the PoshDoc Content solely in accordance with the terms of this Agreement and solely in
                                connection with your use of our Site. You understand and agree that PoshDoc rights in any PoshDoc
                                Content are valid and protected in all forms, media, and technologies existing now or developed in the
                                future. You may not obscure or remove any proprietary rights notices contained in or on the PoshDoc
                                Content.</p>
                            <p>On the condition that you comply with all of your obligations under this Agreement and subject to
                                additional terms of any third-party licenses applicable to our Site, we hereby grant to you a limited,
                                revocable, non-exclusive, non-transferable, non-sublicensable right to access and use our Site solely for
                                your own, non-commercial personal development purposes. We reserve all rights not otherwise expressly
                                granted by this Agreement. If you do not comply with this Agreement, we reserve the right to revoke any
                                license granted in this Agreement and limit your access to our Site. Any use of our Site that exceeds the
                                rights expressly granted in this Agreement is strictly prohibited and constitutes a violation of this
                                Agreement, which may result in the termination of your right to access and use our Site. You are not
                                acquiring any rights in or to the PoshDoc Materials other than a non-exclusive right to access and use this
                                Site solely in accordance with the terms of this Agreement.</p>
                            <p>PoshDoc or its licensors exclusively own all right, title and interest in and to our Site, PoshDoc Content,
                                PoshDoc Materials, and Feedback, including but not limited to, all ideas, inventions, inferences,
                                discoveries, source and object software code, developments, derivative works, enhancements, upgrades,
                                fixes and patches, formats and processes, and all images, trademarks, service marks, logos and icons
                                displayed or related therein or thereto (collectively, “PoshDoc IP”). Except as expressly provided herein,
                                you have no right, license, or authorization with respect to any of the PoshDoc IP. You shall not assert
                                any claims to the contrary or otherwise do anything inconsistent with the allocation of ownership herein,
                                including, but not limited to, challenging the validity of the authorizations or any intellectual property
                                rights granted herein. In the event you are ever deemed to be the owner of any of the PoshDoc IP, you
                                shall immediately take all necessary steps to evidence, transfer, perfect, vest, or confirm PoshDoc’s right,
                                title and interest in the PoshDoc IP. PoshDoc is not transferring or granting to you any right, title, or
                                interest in or to (or granting you any license or other permissions in or to) any PoshDoc IP. The sole
                                exception of the foregoing reservation of rights are the limited rights granted to you to use our Site, which
                                shall automatically terminate upon expiration or termination of this Agreement. Any unauthorized use of
                                any PoshDoc IP, whether owned by us or other parties, may violate copyright laws, trademark laws,
                                privacy and publicity laws and communications regulations and statutes. Trademarks owned by third
                                parties are the property of those respective third parties.
                            </p>
                            <p>You may not: (1) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially
                                exploit or make available to any third party any portion of our Site in any way; (2) copy, modify, adapt,
                                alter, translate, create derivative works, reverse engineer, decompile, disassemble, or otherwise attempt to
                                learn the source code, structure, or ideas upon which our Site is based; (3) use our Site or PoshDoc
                                Materials to develop a competing service or product; (4) use any device, software, or routine intended to
                                damage or otherwise interfere with the proper functioning of our Site, servers, or networks connected to
                                our Site or take any other action that interferes with any other person’s use of our Site; (5) decrypt,
                                transfer, create Internet links to our Site, or “frame” or “mirror” our Site on any other server or wireless or
                                Internet-based device; (6) use or merge our Site or any component thereof with other software, databases,
                                or services not provided or approved by us; (7) circumvent or attempt to circumvent any electronic
                                protection measures in place to regulate or control access to our Site; (8) use our Site for unlawful
                                purposes; (9) develop, distribute, or sell any software or other functionality capable of launching, being
                                launched from, or otherwise integrated with our Site; (10) use any bot, spider, or other automatic or
                                manual device or process for the purpose of harvesting or compiling information on our Site for any
                                reason; (11) access or attempt to access any other user’s account; (12) use any PoshDoc Materials made
                                available through our Site in any manner that misappropriates any trade secret or infringes any copyright,
                                trademark, patent, rights of publicity, or other proprietary right of any party; (13) introduce or attempt to
                                introduce into our Site any virus, rogue program, Trojan horse, worm or other malicious or intentionally
                                destructive code, software routines, or equipment components designed to permit unauthorized access to
                                or disable, erase, or otherwise harm our Site, or perform any such actions; (14) introduce or attempt to
                                introduce into our Site any back door, time bomb, drop dead device, or other software routine designed to
                                disable a computer program automatically with the passage of time or under the positive control of an
                                unauthorized person; (15) delete, modify, hack, or attempt to change or alter our Site, PoshDoc Materials,
                                or notices on our Site; (16) connect to or access any PoshDoc computer system or network other than our
                                Site; or (17) impersonate any other person or entity to use or gain access to our Site.
                            </p>
                            <p>PoshDoc may terminate your use of the Site or any of PoshDoc’s features or services at any time and for
                                any reason without notice. You agree that if your use of the Site is terminated pursuant to these TOU, you
                                will not attempt to use the Site under any name, real or assumed. Except as otherwise provided in the
                                Privacy Policy, or as required by applicable law, we have no obligation, whether before or after the
                                termination of your use of the Site, to return or otherwise provide to you any information to use, or any
                                other information that we have that relates to you.</p>
                            <p>We use commercially reasonable efforts to maintain our Site, but we are not responsible for any defects or
                                failures associated with our Site, any part thereof, or any damages (such as lost income, opportunities or
                                any other consequential or indirect damages) that may result from any such defects or failures. Our Site
                                may be inaccessible or inoperable for any reason, including, without limitation: (1) equipment
                                malfunctions; (2) periodic maintenance procedures or repairs which we may undertake from time to time;
                                or (3) causes beyond our reasonable control or which we could not reasonably foresee. You understand
                                that our Site is provided over the Internet, so the quality and availability of our Site may be affected by
                                factors outside of our control. Our Site is not intended to be available 100% of the time and we do not
                                make any representations, warranties, or guarantees regarding the reliability or availability of our Site. We
                                do not represent, warrant, or guarantee that our Site will always be available or are completely free of
                                human or technological errors. We will not be liable to you or any third party for damages or losses
                                related to our Site being unavailable.</p>
                            <p><b>Third Party Links.
                            </b></p>
                            <p>PoshDoc may provide links to third-party web sites. PoshDoc does not recommend and does not endorse
                                the content on any third-party websites. PoshDoc is not responsible for the content of linked third-party
                                sites, sites framed within the Site, third-party sites provided as search results, or third-party
                                advertisements. Your use of third-party websites is at your own risk, and subject to the terms and
                                conditions of use for such sites. PoshDoc does not endorse any product, service, or treatment advertised
                                on the Site. PoshDoc does not control and is not responsible for the privacy practices of such third
                                parties. PoshDoc encourages you to review the privacy policies of each website and application you visit
                                and use. Any third-party software included in this Site is licensed subject to the additional terms of the
                                applicable third-party license</p>
                            <p><b>Mobile Use</b></p>
                            <p>Your contract with your mobile network provider (“Mobile Provider”) will continue to apply when
                                accessing or using our Site on your mobile, handheld device (“Mobile Device”). You understand that your
                                Mobile Provider may charge you fees for your use of its network connection services while accessing or
                                using our Site, for data downloading, e-mail, text messages, for roaming, and other Mobile Provider or
                                third party charges. YOU ACCEPT RESPONSIBILITY FOR ALL MOBILE PROVIDER FEES. Please
                                review our Privacy Policy [link] for more information on how we administer SMS text messaging,
                                including details on how to opt-out.
                            </p>
                            <p><b>Limitation on Use by Minors</b></p>
                            <span><b>YOU MUST BE AT LEAST AGE EIGHTEEN (18) TO USE OUR SITE. BY ACCESSING, USING
                                AND/OR SUBMITTING INFORMATION TO OR THROUGH OUR SITE, YOU REPRESENT THAT
                                YOU ARE NOT YOUNGER THAN AGE 18.
                            </b></span>
                            <p>Our service is intended for use by individuals who are at least eighteen (18) years of age or such older age
                                as may be required by applicable state laws in the jurisdiction in which an individual accesses the Site.
                                The Site is not designed or intended to attract, and is not directed to, children under eighteen (18) years of
                                age. If PoshDoc obtains actual knowledge that it has collected personal information through the Site from
                                a person under eighteen (18) years of age, PoshDoc will use reasonable efforts to refrain from using such
                                personal information or maintaining it in retrievable form. Individuals under eighteen (18) years of age
                                (and/or their parent or guardian) may contact PoshDoc if they believe their personal information has been
                                collected and/or displayed on the Site and request its removal and/or destruction.
                            </p>
                            <p>Please note that PoshDoc is not required to erase or otherwise eliminate, or enable erasure or elimination
                                of such content or information in certain circumstances, such as, for example: (1) when an international,
                                federal, state, or local law, rule, or regulation requires PoshDoc to maintain the content or information;
                                (2) when PoshDoc maintains the content or information on behalf of a user’s healthcare provider as part
                                of such user’s medical record; (3) when the content or information is stored or posted to the Site by a third
                                party other than the user (including any content or information posted by the user that was stored,
                                republished, or reposted by the third party); (4) when PoshDoc anonymizes the content or information, so
                                that such user cannot be identified individually; (5) when such user does not request that such information
                                be removed from the Site; and (6) when such user has received compensation or other consideration for
                                providing the content or information.</p>
                            <p>The foregoing is not intended to be an admission that PoshDoc is subject to the Child Online Privacy
                                Protection Act, the Federal Trade Commission’s Children’s Online Privacy Online Protection Rule(s), or
                                any similar international, federal, state, or local laws, rules, or regulations.</p>
                            <p><b>Account Information
                            </b></p>
                            <p>When you create an account with PoshDoc, you are creating a direct customer relationship with PoshDoc
                                that enables you to access and/or utilize various functions of the Site. As part of that relationship, you will
                                provide certain information to PoshDoc, including but not limited to, your name, email address, shipping
                                address, phone number, and certain transactional information that PoshDoc does not consider to be
                                7
                                “protected health information” or “medical information”. PoshDoc will use the data and information that
                                you provide in accordance with its Privacy Policy.</p>
                            <p>You may not upload, post, or transmit any information in connection with your account that: (1) would
                                violate or infringe the proprietary, privacy, publicity, or intellectual property rights of PoshDoc, our
                                customers, or any third party; (2) is obscene, defamatory, threatening, harassing, abusive, libelous,
                                hateful, or harmful to any other person or entity; (3) violates any applicable law, statute, ordinance, or
                                regulation; (4) puts in jeopardy the security of your account, PoshDoc, our customers, or our Site; or (5)
                                promotes or displays any of the following content: (a) pornography; (b) violence; (c) racial intolerance or
                                advocacy against any individual, group, or organization; (d) profanity; or (e) illicit drugs and drug
                                paraphernalia. We reserve the right, but have no obligation, to pre-screen, review, flag, filter, modify,
                                refuse, and remove any and all content you upload from the Site. You understand and expressly
                                acknowledge that by using our Site you may be exposed to content that you find offensive, indecent, or
                                objectionable and that we will not be liable to you or any other person or entity for your consumption of
                                any content on our Site.
                            </p>
                            <p>You must immediately notify us if you learn of or have reason to suspect any unauthorized use of your
                                information or any other breach of security. You also agree that you will provide truthful and accurate
                                information to us. We may refuse to grant you access to our Site for any reason, including, without
                                limitation, if we have reason to believe that you are impersonating someone else, that the information you
                                provide is protected by trademark or other proprietary rights, or is vulgar or otherwise offensive.
                            </p>
                            <p>We welcome your comments, feedback, information, or materials regarding our Site or any of our other
                                products or services (collectively, “Feedback”). Your Feedback will become our property upon your
                                submission to us. By submitting your Feedback to us, you agree to assign, and hereby irrevocably assign
                                to us, all right, title, and interest in and to the Feedback and all copyrights and other intellectual property
                                rights embodied in such Feedback on a worldwide basis. We will be free to use, copy, distribute, publish
                                and modify your Feedback on an unrestricted basis, without compensation to you. Moreover, you hereby
                                assign or waive, as the case may be, any moral rights that you may have in or to the Feedback.
                            </p>
                            <p><b>Data Retention
                            </b></p>
                            <p>PoshDoc may retain your information for (1) as long as it believes necessary; (2) as long as necessary to
                                comply with its legal obligations, resolve disputes, and/or enforce agreements; or (3) as long as needed to
                                provide its users with the Services. PoshDoc may dispose of or delete any such information at any time,
                                except as set forth in any other agreement or document executed by PoshDoc or as required by law.
                                Please see the Privacy Policy for additional information about how your data will be handled by
                                PoshDoc.</p>
                            <p><b>Transactions
                            </b></p>
                            <p>In connection with any transaction they conduct through the Site, users of the Site may be asked to supply
                                certain information relevant to the transaction, including, without limitation, credit card numbers and
                                expiration dates, billing addresses, shipping addresses, phone numbers, or email addresses. By submitting
                                such information, users grant PoshDoc without charge the irrevocable, unencumbered, universal, and
                                perpetual right to provide such information to third parties for the purpose of facilitating the transaction.</p>
                            <p>We use and rely upon electronic records and electronic signatures for the execution and delivery of these
                                Terms and Conditions and any other agreements, undertakings, notices, disclosures or other documents,
                                communications or information of any type sent or received in accordance with these Terms and
                                Conditions and in performing our obligations and exercising our rights under these Terms and
                                Conditions. Neither you nor PoshDoc will prevent or inhibit in any way the other party from printing,
                                saving, or otherwise storing electronic records sent or otherwise made available to the other party. You
                                agree not to contest the authorization for, or validity or enforceability of, electronic records and electronic
                                signatures, or the admissibility of copies thereof, under any applicable law relating to whether certain
                                agreements, files, or electronic records are to be in writing or signed by you to be bound thereby. You will
                                bear your own costs and expenses in conducting business electronically, and will undertake all steps
                                necessary, including software, hardware, and other equipment upgrades and purchases, in order to be able
                                to conduct business electronically.
                            </p>
                            <p><b>Waiver</b></p>
                            <p>No delay or omission by PoshDoc to exercise any right or power it has under these TOU or to object to
                                the failure of any covenant of you to be performed in a timely and complete manner, shall impair any such
                                right or power or be construed as a waiver of any succeeding breach or any other covenant. Any waivers
                                by PoshDoc must be in writing and signed by an authorized representative of PoshDoc.</p>
                            <p><b>Jurisdictional Issues</b></p>
                            <p>These TOU, the Privacy Policy, and PoshDoc’s collection, use, and disclosure of information are
                                governed by United States law.</p>
                            <p><b>Representations</b></p>
                            <p>You hereby represent and warrant that: (1) you (a) are at least the age of 18; and (b) have the power and
                                authority to enter into and perform your obligations under this Agreement; (2) all information provided by
                                you to us is truthful, accurate and complete; (3) you will comply with the terms and conditions of this
                                Agreement and any other agreement to which you are subject that is related to your use of our Site, your
                                Feedback, user content, account information, or any part thereof; (4) if applicable, you have provided and
                                will maintain accurate and complete information with us, including, without limitation, your legal name,
                                email address, and any other information we may reasonably require; (5) your access to and use of our
                                Site or any part thereof will not constitute a breach or violation of any other agreement, contract, terms of
                                use or any law or regulation to which you are subject; (6) you will immediately notify us in the event that
                                you learn or suspect that the contact information you provided to us has been disclosed or otherwise made
                                known to any other person; (7) you will not use our Site in order to gain competitive intelligence about us,
                                our Site, or any product or service offered via our Site or to otherwise compete with us; and (8) your user
                                content does not violate the rights of any third party, including, without limitation, the intellectual
                                property, privacy or publicity rights of any third party, and constitutes an original work of authorship by
                                you.</p>
                            <p><b>Disclaimers</b></p>
                            <p>YOUR ACCESS AND USE OF THE SITE IS VOLUNTARY AND IS AT YOUR SOLE RISK.
                                PoshDoc DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, EITHER EXPRESS
                                OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE
                                IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’
                                RIGHTS, SATISFACTORY QUALITY AND FITNESS FOR PARTICULAR PURPOSE WITH
                                REGARD TO THE SITE AND WITH RESPECT TO ANY INFORMATION, CONTENT, PRODUCT,
                                SERVICE, MERCHANDISE, OR OTHER MATERIAL PROVIDED ON OR THROUGH THE SITE.
                                PoshDoc DOES NOT WARRANT OR GUARANTEE THE ACCURACY, COMPLETENESS,
                                RELIABILITY, TIMELINESS, OR USEFULNESS OF THE SITE.
                            </p>
                            <p>WITHOUT LIMITATION, WE MAKE NO WARRANTY THAT OUR SITE (INCLUDING THE
                                PoshDoc CONTENT AND MATERIALS) WILL MEET YOUR REQUIREMENTS, THAT USE OF
                                THE FOREGOING WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT
                                USING PoshDoc PLATFORM, PRODUCTS, OR SERVICES WILL RESULT IN ANY SPECIFIC
                                RESULTS, THAT THE RESULTS OBTAINED FROM THE USE OF THE FOREGOING OR ANY
                                INFORMATION OR CONTENT FOUND ON OUR SITE WILL BE ACCURATE OR RELIABLE,
                                THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF OUR SITE WILL BE
                                CORRECTED, THAT OUR SITE AND ANY CONTENT OR INFORMATION FOUND ON OUR SITE
                                WILL BE VIRUS-FREE, OR THAT THE QUALITY OF ANY INFORMATION, CONTENT, OR
                                OTHER MATERIALS OBTAINED THROUGH OUR SITE WILL MEET YOUR EXPECTATIONS.
                                PoshDoc WILL HAVE NO LIABILITY REGARDING ANY LOSS OF DATA.</p>
                            <p>ANY CONTENT OR OTHER MATERIALS DOWNLOADED OR OTHERWISE OBTAINED
                                THROUGH THE USE OF OUR SITE IS DONE AT YOUR SOLE RISK, AND YOU WILL BE
                                SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE DOWNLOAD OF ANY
                                SUCH MATERIAL. FURTHER, PLEASE NOTE THAT NO ADVICE OR INFORMATION,
                                OBTAINED BY YOU THROUGH OUR SITE OR ANY PRODUCT WILL CREATE ANY
                                WARRANTY NOT EXPRESSLY PROVIDED FOR IN THIS AGREEMENT. SOME JURISDICTIONS
                                DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, SOME OF
                                THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                            <p><b>Limitation of Liability</b></p>
                            <p>IN NO EVENT SHALL PoshDoc, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR ITS SUPPLIERS
                                BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, EXEMPLARY,
                                OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR
                                RESULTING FROM ACCESS TO, THE USE OR LOSS OF USE, OR INABILITY TO USE THIS SITE
                                OR ANY OTHER SITE LINKED TO THIS SITE, UNAUTHORIZED ACCESS TO OR ALTERATION
                                OF YOUR TRANSMISSIONS OF DATA, STATEMENT OR CONDUCT OF ANY THIRD PARTY,
                                ANY INFORMATION, SITE, ERRORS, OR OMISSIONS IN THE CONTENT HEREOF, OR ANY
                                OTHER MATTER RELATING TO YOUR USE OF OR ACCESS TO THE SITE. SOME STATE
                                STATUTES MAY APPLY REGARDING LIMITATION OF LIABILITY.</p>
                            <p>YOU HEREBY RELEASE AND FOREVER WAIVE AND DISCHARGE ANY AND ALL CLAIMS
                                AND LIABILITIES YOU MAY HAVE AGAINST PoshDoc FROM ANY AND ALL MATTERS
                                RELATING TO YOUR USE OF THE SITE.
                            </p>
                            <p>IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR IF YOU SEEK TO ASSERT
                                ANY CLAIM AGAINST PoshDoc ARISING OUT OF OR RELATING TO THE TERMS AND
                                CONDITIONS OF YOUR USE OF THE SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                                DISCONTINUE USING THE SITE.
                            </p>
                            <p>IF YOU ARE A CALIFORNIA RESIDENT OR COULD OTHERWISE CLAIM THE PROTECTIONS
                                OF CALIFORNIA LAW, YOU FURTHER EXPRESSLY WAIVE THE PROVISIONS OF SECTION
                                1542 OF THE CALIFORNIA CIVIL CODE, WHICH READS AS FOLLOWS: “A GENERAL
                                RELEASE DOES NOT EXTEND TO THE CLAIMS WHICH THE CREDITOR DOES NOT KNOW
                                OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE
                                WHICH, IF KNOWN BY HIM OR HER, MUST HAVE MATERIALLY AFFECTED HIS OR HER
                                SETTLEMENT WITH THE DEBTOR.” YOU ACKNOWLEDGE THAT YOU HAVE READ AND
                                UNDERSTAND SECTION 1542 OF THE CALIFORNIA CIVIL CODE, AND YOU HEREBY
                                EXPRESSLY WAIVE AND RELINQUISH ALL RIGHTS AND BENEFITS UNDER THAT SECTION
                                AND ANY LAW OF ANY JURISDICTION OF SIMILAR EFFECT WITH RESPECT TO YOUR
                                RELEASE OF ANY CLAIMS YOU MAY HAVE AGAINST RELEASED PARTIES.</p>
                            <p><b>Entire Agreement</b></p>
                            <p>These TOU and any other agreements PoshDoc may post on the Site or that you and PoshDoc may
                                execute from time to time constitute the entire agreement between PoshDoc and you in connection with
                                your use of the Site and supersede any prior agreements between PoshDoc and you regarding use of the
                                Site, including prior versions of these TOU.
                            </p>
                            <p><b>Governing Law and Jurisdiction</b></p>
                            <p>Accessing this Site or use of any of its content or services from locations where such access or use of its
                                content or services is illegal is prohibited. Notwithstanding the laws of the location from which you
                                access the Site, you agree that the statutes and laws of the State of Delaware, notwithstanding any
                                principles of conflicts of law, will apply to all matters relating to the use of the Site. You irrevocably
                                submit to the exclusive jurisdiction of the courts of the State of Delaware, New Castle County, in respect
                                of all matters and disputes arising hereunder and waive any defense of lack of personal jurisdiction in that
                                jurisdiction.
                            </p>
                            <p>If any part of these TOU are deemed unlawful, void, or unenforceable, such part will be deemed severable
                                and will not affect the validity and enforceability of any remaining provisions</p>
                            <p><b>Arbitration</b></p>
                            <p>In the event of any dispute between you and PoshDoc, you agree that such dispute shall be resolved
                                through arbitration in New York County, New York by the American Health Lawyers Association
                                (“AHLA”) Dispute Resolution Service and conducted pursuant to the applicable AHLA Rules of
                                Procedure for Arbitration (the “Arbitration Rules”). The fees and expenses of any arbitration shall be
                                allocated in accordance with the “Standard Allocation” provisions of the Arbitration Rules. The award of
                                the arbitrator shall be final, binding and conclusive on all parties to the arbitration.</p>
                            <p></p>
                            <p>You hereby agree that you understand the consequences of agreeing to binding arbitration under this
                                Section, including giving up any constitutional rights to have the dispute determined by a court of law or
                                by a jury; that discovery of information in arbitration may be limited; and that the arbitration decision will
                                be final and binding, except to the limited extent that judicial review might be available. ALL CLAIMS
                                AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE ARBITRATED OR
                                LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS OR THROUGH
                                PARTICIPATION IN A CLASS ACTION OR REPRESENTATIVE ACTION. CLAIMS OR DISPUTES
                                OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                                CONSOLIDATED WITH THOSE OF ANY OTHER USER.</p>

                            <p><b>Equitable Relief</b> </p>
                            <p> You agree that breach of the provisions of this Agreement would cause irreparable harm and significant
                                injury to us which would be both difficult to ascertain and which would not be compensable by damages
                                alone. As such, you agree that we have the right to enforce the provisions of this Agreement by injunction
                                (without necessity of posting bond), specific performance, or other equitable relief without prejudice to
                                any other rights and remedies we may have for your breach of this Agreement.</p>
                            <p> <b>Changes to this Agreement</b></p>
                            <p> When we make changes, we will revise the “last modified” date at the bottom of this document. We
                                encourage you to review these TOU periodically. Your continued use of PoshDoc constitutes your
                                agreement to the changed TOU.
                            </p>
                            <p><b>Assignment</b> </p>
                            <p> You may not assign, transfer, or sell (voluntarily or by operation of law) your rights or obligations under
                                this Agreement, nor delegate your duties hereunder to any other person, without our prior written consent.
                                Any purported assignment without our consent will be void and will constitute a breach of this
                                Agreement. We may assign this Agreement or delegate or subcontract our obligations under this
                                Agreement at any time.
                            </p>
                            <p><b>Survival</b> </p>
                            <p>The provisions of this Agreement that by their content are intended to survive the expiration or
                                termination of this Agreement, including, without limitation, provisions governing ownership and use of
                                intellectual property, representations, disclaimers, warranties, liability, indemnification, governing law,
                                jurisdiction, venue, remedies, rights after termination, and interpretation of this Agreement, will survive
                                the expiration or termination of this Agreement for their full statutory period. </p>
                            <p><b>Contact Us</b> </p>
                            <p>If you have questions, email <a href="mailto:Team@poshdoc.com">Team@poshdoc.com</a> or submit your questions here
                                <Link href={"contact_us"}>  https://www.poshdoc.com/contact_us.</Link>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <button className="btn mt-1 btn-lg-primary" onClick={(e) => {
                        setModal(false)
                    }}>Close</button>
                </ModalBody>
            </Modal>{" "}
        </div>
    )
}

export default ServicesModal
