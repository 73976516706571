import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

const TopBar = () => {
  const [ready, setReady] = useState(false);
  const [promo, setPromo] = useState("");
  const [amount, setAmount] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const { promo } = router.query;

      if (promo) {
        // Mock API call to fetch the promo amount based on the promo code
        fetchPromoAmount(promo).then((discount) => {
          setPromo(promo);
          setAmount(discount);
          setReady(true);
        });
      } else {
        const savedDiscount = Cookies.get("discount");
        if (savedDiscount) {
          fetchPromoAmount(savedDiscount).then((discount) => {
            setPromo(savedDiscount);
            setAmount(discount);
            setReady(true);
          });
        }
        setReady(true);
      }
    }
  }, [router.isReady, router.query]);

  // Mock function to simulate fetching promo amount from an API
  const fetchPromoAmount = async (promoCode) => {
    // Simulate an API call delay
    return new Promise((resolve) => {
      setTimeout(() => {
        if (promoCode === "GET30") {
          resolve("$30");
        } else if (promoCode === "SAVE20") {
          resolve("$20");
        } else {
          resolve("$0");
        }
      }, 1000);
    });
  };

  const handleClick = async () => {
    setButtonLoading(true);
    const purchase = {
      title: "Compounded Semaglutide",
      description:
        "Monthly prescription to Semaglutide for weight-loss. Includes unlimited messaging with medical provider. Subject to medical eligibility. Cost of medication included. No insurance necessary.",
      priceDescription: "$297 / month",
      todayPriceDescription: "$197 for first month",
      membershipTitle: "Membership Benefits:",
      bullets: [
        "Initial consultation with medical provider",
        "Semaglutide, if medically eligible",
        "Monthly check-ins with doctor",
        "Unlimited messaging",
      ],
      buttonName: "Start Now",
      removePrice: "$297/month",
      buttonPrice: "$197 for first month*",
      buttonText: "Membership billed every 28 days",
      checkoutPrice: "297",
      totalTodayPrice: 197,
      key: "SUBSCRIPTION_ID",
    };

    let copyPurchase = { ...purchase };
    // if (promo === "SAVE20") {
    //   copyPurchase.totalTodayPrice -= 20;
    // } else if (promo === "GET30") {
    //   copyPurchase.totalTodayPrice -= 30;
    // }

    delete copyPurchase.bullets;
    Cookies.set("plan", JSON.stringify(copyPurchase));
    Cookies.set("discount", promo);
    Cookies.set(
      "cart",
      JSON.stringify({
        img: "v1678202048/NextMed/GLP_-_1_gcunmh.png",
        name: purchase.title,
        type: "weightloss",
        price: purchase.checkoutPrice,
        weightlossKey: purchase.key,
        month: 1,
        show_price: null,
        cartName: purchase.cartName,
      })
    );
    router.push(`/purchase`);
  };

  return (
    <>
      {promo && (
        <div className="panel-banner weight-loss-banner">
          <ReactPlaceholder
            showLoadingAnimation
            ready={ready}
            rows={1}
            className="small-baner-skel"
          >
            <span>
              Coupon {promo} Applied. Sign up today and get {amount} off your
              first month. {" "}
              <button onClick={handleClick} disabled={buttonLoading}>
                Start Now -&gt;
              </button>
            </span>
          </ReactPlaceholder>
        </div>
      )}
    </>
  );
};

export default TopBar;
